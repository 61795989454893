<template>
    <div>
        <div class="dd-user-item" v-for="ddUser in ddUserList" :key="ddUser.id">
            <p @click="jumpto('/', { u: ddUser.id })">
                <span>{{ ddUser.group_name }}</span> - <span>{{ ddUser.name }}</span>
            </p>
        </div>
        <div class="send-daily-message-button fs-big bg-blue" @click="send_daily_message">发送每日信息</div>
    </div>
</template>
<script>
import axios from 'axios'
export default {
    name: '',
    components: {},
    data() {
        return {
            ddUserList: []
        }
    },
    created() {
        axios.get('/api/supervision/dd_user/', { params: { is_faked: true } }).then((res) => {
            this.ddUserList = res.data
        })
    },
    mounted() {},
    methods: {
        send_daily_message() {
            // http://rcsp.rarb.cn/#/?next=sg?pre_filter=id__in&ids=[17]
            axios
                .post('api/daily_message/')
                .then((res) => {
                    console.debug(res.data.messages)
                })
                .catch((err) => {
                    console.error(err.message)
                })
        }
    }
}
</script>

<style lang="less" scoped>
.dd-user-item {
    padding-top: 30px;
}
.send-daily-message-button {
    position: fixed;
    bottom: 10vh;
    right: 20vw;
}
</style>
